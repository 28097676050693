<template>
  <div class="main">
    <el-table
        :data="dataBox.list"
        stripe
        style="width: 100%">
      <el-table-column prop="head_img" label="头图" width="180" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.head_img" style="width:80px;height:80px;" @click="router('detail', { id: scope.row.id })">
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="180"></el-table-column>
      <el-table-column prop="cate_name" label="分类"></el-table-column>
      <el-table-column prop="money" label="价格"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          {{ scope.row.status === 1 ? '已上架' : '已下架'}}
        </template>
      </el-table-column>
      <el-table-column prop="apply_time" label="上传时间" width="200"></el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="scope">
          <div class="opt-btns">
            <div class="op-btn" @click="switchStatus(scope.row)">
              <img src="/nft/btn1.png" />
              <span>{{ scope.row.status === 1 ? '下架' : '上架'}}</span>
            </div>
            <div class="op-btn" @click="router('upload', { id: scope.row.id })">
              <img src="/nft/btn2.png" />
              <span>编辑</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <div class="page-info">
        {{ dataBox.total }} 条信息 共 {{ dataBox.limit }} 页
      </div>
      <el-pagination
          background
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          @current-change="loadList"
          :page-size="dataBox.size"
          :total="dataBox.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "nft-management",
  data() {
    return {
      dataBox: {
        list: [],
        page: 1,
        size: 10, // 每页数量
        total: 0, // 总条数
        limit: 0, // 总页数
      }
    }
  },
  created(){
    this.loadList();
  },
  methods: {
    loadList(page) {
      this.dataBox.page = page || 1
      this.$api.nft_product.getMemberList({
        page: this.dataBox.page,
        size: this.dataBox.size
      }).then((res) => {
        this.dataBox.limit = res.data.limit
        this.dataBox.total = res.data.total
        this.dataBox.list = res.data.list
      });
    },
    switchStatus(item) {
      this.$confirm(`是否${item.status === 1 ? '下架' : '上架'}藏品 ${item.name}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.nft_product.switchProductStatus({
          id: item.id,
        }).then((res) => {
          item.status = res.data
        });
      }).catch(() => {});
    },
    router(url, query) {
      this.$router.push({
        path: url,
        query: query
      });
    },
  }
}
</script>

<style lang="less" scoped>
.main {
  width:1200px;
  margin:100px auto;
}
/deep/.el-table th.el-table__cell {
  background:#00272D;
  color:#ffffff;
}
/deep/.el-table tr {
  background:#00272D;
  color:#ffffff;
}
/deep/.el-table__row--striped td {
  background: #00191D !important;
  color:#ffffff;
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background:#00272D;
  color:#ffffff;
}
/deep/.el-table td.el-table__cell {
  border-bottom:10px solid #000000 !important;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom:10px solid #000000 !important;
}
/* 去除表格的边框 */
/deep/ td.el-table__cell {
  border: none;
}
/deep/ th.el-table__cell {
  border: none;
}
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
}
/deep/.el-table, .el-table__expanded-cell {
  background-color: transparent;
}
.page {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .page-info {
    color: #B2BBD7;
    font-size: 14px;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: transparent;
    color: #418CFF;
    border: 1px solid #009AB1
  }
  /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
    background-color: transparent;
    border: 1px solid #B2BBD7
  }
  /deep/ .el-pagination span:not([class*="suffix"]) {
    padding: 0 10px;
  }
  /deep/ .el-input__inner {
    background-color: transparent;
  }
}
.opt-btns {
  display: flex;
  flex-direction: row;
  .op-btn {
    text-align: center;
    color:#ffffff;
    height:30px;
    line-height: 30px;
    width:100px;
    position: relative;
    margin-right:5px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    flex: 1;
    img {
      position: absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      z-index: 0;
    }
    span {
      position: relative;
    }
  }
}
.el-popper {
  padding:12px;
  background: #000000 !important;
}
</style>
